import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Slider from "react-slick";

import { stripslashes } from "../Helpers/SettingHelper";
import { Scrollbars } from 'react-custom-scrollbars';

class MenuNavigation extends Component {

  constructor(props) {
    super(props);
    this.state = {
      navigateMenu: []
    }
	
  }

  navigateMenuList(){
       
	    let navigateMenuArr = this.props.navigateMenu;
	    var selectedNav = this.props.productState.selectedNavigation;
		if(Object.keys(navigateMenuArr).length > 0) {
			const mainMenu = navigateMenuArr.map((loaddata, index) => {
				if (loaddata.menu_type == "main") {
					return (

							<li key={index+1} className={(selectedNav === loaddata.pro_cate_slug)?"active":""}>
							<Link to={"/menu/category/" + loaddata.pro_cate_slug} title={loaddata.menu_custom_title} >
							{ stripslashes(loaddata.menu_custom_title) }
							</Link>
							</li>

						  );
				} else if (loaddata.menu_type == "sub") {
					return (
						  <li key={index+1} className={(selectedNav === loaddata.pro_cate_slug)?"active":""}>
							<Link to={"/menu/subcategory/" + loaddata.pro_subcate_slug} title={loaddata.menu_custom_title} >
							{ stripslashes(loaddata.menu_custom_title) }
							</Link>
						  </li>
						  );
				}
			});
			
			return mainMenu;
			
		} else {
			
			return '';
		}

   }
   
   navigatemobileMenuList(){
       
	    let navigateMenuArr = this.props.navigateMenu;
	    var selectedNav = this.props.productState.selectedNavigation;
		if(Object.keys(navigateMenuArr).length > 0) {
			const mainMenu = navigateMenuArr.map((loaddata, index) => {
				if (loaddata.menu_type == "main") {
					return (

					<div key={index+1} className={(selectedNav === loaddata.pro_cate_slug)?"menu-slide active":"menu-slide"}>
					<Link to={"/menu/category/" + loaddata.pro_cate_slug} title={loaddata.menu_custom_title} >
					{ stripslashes(loaddata.menu_custom_title) }
					</Link>
					</div>

				);
				} else if (loaddata.menu_type == "sub") {
					return (

						<div key={index+1} className={(selectedNav === loaddata.pro_cate_slug)?"menu-slide active":"menu-slide"}>
						<Link to={"/menu/subcategory/" + loaddata.pro_subcate_slug} title={loaddata.menu_custom_title} >
						{ stripslashes(loaddata.menu_custom_title) }
						</Link>
						</div>
						  );
				}
			});
			
			return mainMenu;
			
		} else {
			
			return '';
		}

   }
      


  render() {

		var settingsNav = {
			dots: false,
			infinite: false,
			speed: 500,
			slidesToShow: 4,
			slidesToScroll: 1,
			initialSlide: 0,
			responsive: [
			  {
				breakpoint: 1024,
				settings: {
				  slidesToShow: 3,
				  slidesToScroll: 1,
				  infinite: true,
				  dots: false
				}
			  },
			  {
				breakpoint: 700,
				settings: {
				  slidesToShow: 2,
				  slidesToScroll: 1,
				  initialSlide: 2
				}
			  },
			  {
				breakpoint: 520,
				settings: {
				  slidesToShow: 1,
				  slidesToScroll: 1
				}
			  }
			]
		  };
		  

	let navigateMenuArr = this.props.navigateMenu;
	var navInxd = this.props.productState.catNavIndex;
		navInxd = (navInxd != '')?navInxd:0;

		return (
		
			<div className="sidebar_menusec">
				<div className="inner_menusec">
				{ (Object.keys(navigateMenuArr).length > 0) ? 
				<Scrollbars style={{ height: '100%'}}>
				<><ul className="sidemenu_list">	{this.navigateMenuList()}
				</ul>
				</>				
				</Scrollbars>
				: "" }
				</div>
				
				{ (Object.keys(navigateMenuArr).length > 0) ? 
				<><div className="mobi-product-menu-list">
					<Slider {...settingsNav}>
					{this.navigatemobileMenuList()}	
					</Slider>
				</div></>
				: "" }

			</div>
			)
  }
}

export default (MenuNavigation);
