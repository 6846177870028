/* eslint-disable */
import { takeEvery, call, put } from 'redux-saga/effects';
import { GET_ORDER_DETAIL, SET_ORDER_DETAIL } from '../actions';
import { appId, apiUrl, deliveryId, getHeader } from "../components/Helpers/Config";
import Axios from 'axios';
import cookie from 'react-cookies';

export const watchGetOrderDetail = function* () {
  yield takeEvery(GET_ORDER_DETAIL, workerGetOrderDetail);
}

function* workerGetOrderDetail({ orderId }) {
  try {
    const uri = apiUrl + 'reports/order_history?app_id=' + appId + "&local_order_no=" + orderId + "&customer_id=" + cookie.load('UserId');
    const result = yield call(Axios.get, uri, getHeader());
	var resultArr = [];
	resultArr.push(result.data);
    yield put({ type: SET_ORDER_DETAIL, value: resultArr });
  } 
  catch {
    console.log('Get Order Detail Failed');
  }
}

