import React, { Component } from 'react';
import { Link,withRouter } from 'react-router-dom';
import cookie from 'react-cookies';
import { connect } from 'react-redux';

import Footer from '../Layout/Footer';

import logo from "../../common/images/logo.png";
import homevideo from "../../common/images/bg-video.mp4";
import homeBg from "../../common/images/home-bg.jpg";


import { GET_MENUDATA} from '../../actions';


class Home extends Component {
	constructor(props) {
		super(props);
		this.state = {
			menu: false,
			menuData:[],
			footerSocialcontent:''
		}
		
	  }
 
	componentDidMount() {

			this.props.getMenuData('main');

			var bgVideo = document.getElementById("bg-video");
			bgVideo.play();
			
			this.videoControls();

	}
	
	 videoControls() {
	  document.getElementById("bg-video").controls = true;
	}

	componentDidUpdate (prevProps) {

		if (this.props.socialsettingData !== prevProps.socialsettingData) {

			   this.setState( { footerSocialcontent: this.props.socialsettingData[0].result_set });
		   }

   }

	componentWillReceiveProps(PropsDt) {

		if(PropsDt.menudata !== this.props.menudata){
			this.setState({menudata: PropsDt.menudata[0].result_set});
		}

	}

	handleMenu(){
			this.setState(prevState => ({menu: !prevState.menu})); 

	}

	handleLogin(){
		cookie.save("loginpopupTrigger", 'Fromhome');
		this.props.history.push('/menu');

}

	

	createLink(menu){
		var url = window.location.pathname;
		if(menu.nav_type==="0")
		{
		  return <Link to={"/page/"+menu.nav_title_slug} title="" className={(url === "/page/"+menu.nav_title_slug)? 'active' : ''}>
			
						  <span>{menu.nav_title}</span>
						</Link>;
	
		  }
		  else if(menu.nav_type==="3")
		  {  
			let target= ""; 
			if(menu.nav_link_type === "self"){
			  target= ""; 
			}else{
			  target = "_blank";
			}
		   return  <a target={target} href={menu.nav_pages} title={menu.nav_title}><span>{menu.nav_title}</span></a>;
		   }
		}
	
		listMainNavigation(){
			 return this.state.menudata.map(function (menu, i) {
				   return  (<li key={i+100}>{this.createLink(menu)}</li>);
		   },this)
	}

	  render() {

		let socialsettingData = [];

		let facebook,instagram,email_info ='';

		if(this.state.footerSocialcontent){

			socialsettingData = this.state.footerSocialcontent;
	
			if(socialsettingData.socailnetwork_facebook_url){
				facebook = <a href={socialsettingData.socailnetwork_facebook_url}  target="_blank" className="facebook icon"></a>
			}

			if(socialsettingData.socailnetwork_instagram_url){
				instagram = <a href={socialsettingData.socailnetwork_instagram_url} target="_blank" className="instagram icon"></a>
			}
		
			if(socialsettingData.socailnetwork_gplus_url){
				email_info = <a href={"mailto:"+socialsettingData.socailnetwork_gplus_url} target="_top" rel="noopener noreferrer" className="mail icon"></a>
			}
		}

		return (
				<div className="home-wrapper">	
				
				<div className="home-bg-video">
					<video autoplay loop poster={homeBg} id="bg-video">
						<source src={homevideo} type="video/mp4" />
					</video>
				</div>	

				<a href="javascript:void(0)" onClick={this.handleMenu.bind(this)} className={(this.state.menu===true)?'trigger-menu menu-icon active':'trigger-menu menu-icon'}>
                    <span className="icon_menu1"></span>
                    <span className="icon_menu2"></span>
                    <span className="icon_menu3"></span>
                </a>
					<div className={(this.state.menu===true)?'full-screen-overlay active':'full-screen-overlay'}>
						<div className="overlay-menu-wrap">
							<div className="logo-header">	
							 <Link to={"/"} title="CHIX Hot Chicken" className="logo"><img src={logo} /></Link>
							</div>
							<div className="overlay-menu">	
								<ul>

								{!cookie.load("UserId") && 
							
									<li>	<a href="javascript:void(0)" onClick={this.handleLogin.bind(this)} title="Login">Login</a></li>
								
								}
								
								{cookie.load("UserId") &&  <li>
									<li><Link to={"/myaccount"} title="My Account">My Account</Link></li>
								</li>}

								<li><Link to={"/menu"} title="Menu">Menu</Link></li>
								{this.state.menudata && this.listMainNavigation()}		
								<li><Link to={"/contact-us"} title="Contact Us">Contact Us</Link></li>	
								</ul>
							</div>
							<div className="social-media-overlay">								
								<span className="follow-us">Follow Us</span>
								{facebook}
								{instagram} 
								{email_info}
							</div>
						</div>
					</div>

                    {/*-------Footer------*/}
					<div className="footer footer-fixed">
					   <Footer />
					</div>

			    </div>
			   )
	  }	
}



const mapStateTopProps = (state) => {

	return {
	  menudata: state.menudata,
	  socialsettingData: state.socialsettings
	}
  }

  const mapDispatchToProps = (dispatch) => {
	return {
	
	  getMenuData: (menuslug) => {
		dispatch({ type: GET_MENUDATA, menuslug });
	  },
	}
  }

export default connect(mapStateTopProps, mapDispatchToProps)(withRouter(Home));

