export const GET_GLOBAL_SETTINGS    		= 'getGlobalSettings';
export const SET_GLOBAL_SETTINGS    		= 'setGlobalSettings';

export const GET_MENU_NAVIGATION    		= 'getMenuNavigation';
export const SET_MENU_NAVIGATION    		= 'setMenuNavigation';

export const GET_PRODUCT_LIST  				= 'getProductList';
export const SET_PRODUCT       				= 'setProduct';
export const GET_PRODUCT_DETAIL  			= 'getProductDetail';
export const SET_PRODUCT_DETAIL     		= 'setProductDetail';
export const GET_ADDONPRODUCT	   			= 'getAddonProList';
export const SET_ADDONPRODUCT    			= 'setAddonPro';

export const GET_ZONE_DETAIL     			= 'getZoneDetail';
export const SET_ZONE_DETAIL     			= 'setZoneDetail';
export const GET_ALLUSERSECADDRDATA         = 'getAllUserSecAddrData';
export const SET_ALLUSERSECADDRDATA         = 'setAllUserSecAddrData';
export const ADD_USERSECADDRDATA        	= 'addUserSecAddrData';

export const GET_CART_DETAIL    			= 'getCartDetail';
export const UPDATE_CART_DETAIL    			= 'updateCartDetail';
export const DELETE_CART_DETAIL    			= 'deleteCartDetail';
export const DESTROY_CART_DETAIL    		= 'destroyCartDetail';
export const SET_CART_DETAIL    			= 'setCartDetail';

export const GET_ACTIVITYCOUNT  	    	= 'getActivityCount';
export const SET_ACTIVITYCOUNT   	    	= 'setActivityCount';

export const GET_ORDER_DETAIL   	    	= 'getOrderDetail';
export const SET_ORDER_DETAIL   	    	= 'setOrderDetail';
export const GET_PRINTORDER   	        	= 'getPrintOrder';
export const SET_PRINTORDER   	  	    	= 'setPrintOrder';
export const GET_ORDERHISTORY   	    	= 'getOrderHistory';
export const SET_ORDERHISTORY   	    	= 'setOrderHistory';

export const GET_PROMOTIONLIST   	    	= 'getPromotionList';
export const SET_PROMOTIONLIST   	    	= 'setPromotionList';

export const GET_PROMOTIONRECEIPT  	    	= 'getPromotionReceipt';
export const SET_PROMOTIONRECEIPT   		= 'setPromotionReceipt';
export const GET_APPLYPROMOTION  	    	= 'getApplyPromotion';
export const SET_APPLYPROMOTION   	    	= 'setApplyPromotion';

export const GET_UPDATECUSTOMERPROFILE      = 'getUpdateCustomerProfile';
export const SET_UPDATECUSTOMERPROFILE      = 'setUpdateCustomerProfile';
export const GET_CUSTOMER_DETAIL        	= 'getCustomerDetail';
export const SET_CUSTOMER_DETAIL        	= 'setCustomerDetail';

export const GET_REWARDEARNED    	    	= 'getRewardEarned';
export const SET_REWARDEARNED   	    	= 'setRewardEarned';
export const GET_REWARDREDEEM    	    	= 'getRewardRedeem';
export const SET_REWARDREDEEM   	    	= 'setRewardRedeem';

export const GET_CORDERDETAIL   	    	= 'getCorderDetail';
export const SET_CORDERDETAIL   	    	= 'setCorderDetail';
export const GET_PORDERDETAIL   	    	= 'getPorderDetail';
export const SET_PORDERDETAIL   	    	= 'setPorderDetail';

export const GET_STATIC_BLOCK    		    = 'getStaticBlock';
export const SET_STATIC_BLOCK    			= 'setStaticBlock';

export const GET_MENUDATA               	= 'getMenuData';
export const SET_MENUDATA   	        	= 'setMenuData';

export const GET_REQUESTPAGEDATA        	= 'getRequestpage';
export const SET_REQUESTPAGEDATA   	    	= 'setRequestpage';

export const GET_CONTACTDATA                = 'getContactData';
export const SET_CONTACTDATA   	            = 'setContactData';

export const GET_SOCIALSETTINGS		    = 'getSocialsetting';
export const SET_SOCIALSETTINGS		    = 'setSocialsetting';