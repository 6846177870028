import React from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';

import App from './App';
import * as serviceWorker from './serviceWorker';

import { getStore } from './store';


import './common/css/font-awesome.min.css';
import './common/css/bootstrap.min.css';
import './common/css/custom.css';
import './common/css/custom-style.css';
import './common/css/slick.css';
import './common/css/responsive.css';

const store = getStore();


ReactDOM.render(
  <React.StrictMode>
 	<Provider store={store}>   <App /></Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
