import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import $ from 'jquery'
import { GET_SOCIALSETTINGS } from '../../actions';
var Parser = require('html-react-parser');


class Footer extends Component {
	
	 constructor(props) {
		super(props);
		this.state = { footerSocialcontent :"" };
	 }
	 
	componentDidMount() {
		this.props.getSocialsetting();

		/*SCROLL TOP*/
        $(window).scroll(function(){
            if ($(this).scrollTop() > 100) {
                $('.scrollup').fadeIn();
            } else {
                $('.scrollup').fadeOut();
            }
        });
        $('.scrollup').click(function(){
            $("html, body").animate({ scrollTop: 0 }, 600);
            return false;
        });
	}
	
	componentDidUpdate(prevProps) {
		
		if (this.props.socialsettingData != prevProps.socialsettingData) {

			this.setState( { footerSocialcontent: this.props.socialsettingData[0].result_set });
		}
		
	  }

	render() {

		var todayTimeSp = new Date();
		var yearSp = todayTimeSp.getFullYear();

		let socialsettingData = [];

		let facebook,instagram,email_info,youtube,twitter,linkedin,pinterest ='';


		if(this.state.footerSocialcontent){
			socialsettingData = this.state.footerSocialcontent;
	  
			if(socialsettingData.socailnetwork_facebook_url){
			  facebook = <a href={socialsettingData.socailnetwork_facebook_url}  target="_blank" className="facebook icon"></a>
			}
			
	  
			if(socialsettingData.socailnetwork_instagram_url){
			  instagram = <a href={socialsettingData.socailnetwork_instagram_url} target="_blank" className="instagram icon"></a>
			}
	  
			if(socialsettingData.socailnetwork_gplus_url){
			  email_info = <a href={"mailto:"+socialsettingData.socailnetwork_gplus_url} target="_top" rel="noopener noreferrer" className="mail icon"></a>
			}
	  
			{/*if(socialsettingData.socailnetwork_youtube_url){
			  youtube = <a href={socialsettingData.socailnetwork_youtube_url} target="_blank" rel="noopener noreferrer"><i className="fa fa-youtube-play"></i></a>
			}
	  
			if(socialsettingData.socailnetwork_twitter_url){
			  twitter = <a href={socialsettingData.socailnetwork_twitter_url} target="_blank" rel="noopener noreferrer"><i className="fa fa-twitter" aria-hidden="true"></i></a>
			}
	  
			if(socialsettingData.socailnetwork_linkedin_url){
			  linkedin = <a href={socialsettingData.socailnetwork_linkedin_url} target="_blank" rel="noopener noreferrer"><i className="fa fa-linkedin" aria-hidden="true"></i></a>
			}
	  
			if(socialsettingData.socailnetwork_pinterest_url){
			  pinterest = <a href={socialsettingData.socailnetwork_pinterest_url} target="_blank" rel="noopener noreferrer"><i className="fa fa-pinterest-p" aria-hidden="true"></i></a>
			}*/}
		  }

		return (	
			<>		
			<div className="footer-inner">
				<p>Copyright {yearSp} Chix Hot Chicken All rights reserved. Design By Jankosoft</p>
				<div className="social-media-footer">
					<span className="follow-us">Follow Us</span>
					{facebook}
                {instagram} 
                {email_info}
				</div>				
			</div> 
			<div className="scrollup"><i className="fa fa-chevron-up" aria-hidden="true"></i></div>
			</>
		);
	}
}

const mapStateTopProps = (state) => {
	return {
		socialsettingData: state.socialsettings,
	}
  }

  const mapDispatchToProps = (dispatch) => {
	return {
		getSocialsetting: () => {
		dispatch({ type: GET_SOCIALSETTINGS });
	  }
	}
  }

export default connect(mapStateTopProps, mapDispatchToProps)(withRouter(Footer));
