/* eslint-disable */
import { takeEvery, call, put } from 'redux-saga/effects';
import { GET_PROMOTIONLIST, SET_PROMOTIONLIST} from '../actions';
import { appId, apiUrl, deliveryId, getHeader } from "../components/Helpers/Config";
import Axios from 'axios';
import cookie from 'react-cookies';

export const watchGetPromotionList = function* () {
  yield takeEvery(GET_PROMOTIONLIST, workerGetPromotionList);
}

var header = {
  headers: { 'X-API-KEY': 'D04Ea1c5-b19c-4B58-b2e9-88F5C0456432' }
}

if(cookie.load('Token')!='' && cookie.load('Token')!=undefined) {
  header.headers.Auth = cookie.load('Token')
}

function* workerGetPromotionList({ customerParam }) {
  try {
    const uri = apiUrl+'promotion_api_v2/promotionlistWhitoutuniqcode?app_id='+appId+customerParam;
    const result = yield call(Axios.get, uri, getHeader ());
	var resultArr = [];
	resultArr.push(result.data);
    yield put({ type: SET_PROMOTIONLIST, value: resultArr });
  } 
  catch {
    console.log('Get Promotion list Failed');
  }
} 


